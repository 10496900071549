<template>
  <div id="app" :class="backgroundClass">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MediaSession from './mixins/media-session';

export default {
  name: 'RadioPootjie',
  created() {
    this.loadStations();
  },
  methods: {
    ...mapActions('RadioStations', ['loadStations']),
  },
  computed: {
    backgroundClass() {
      let wallpapers = ['bg-1', 'bg-2', 'bg-3'];
      let wallpaper = wallpapers[Math.floor(Math.random() * wallpapers.length)];
      return wallpaper;
    },
  },
  mixins: [MediaSession],
};
</script>

<style lang="scss"></style>
