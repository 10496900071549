import Vue from 'vue';
import Vuex from 'vuex';
import RadioStations from './modules/radio-stations';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    RadioStations: RadioStations,
  },
});
