export default {
  name: 'Console',
  data() {
    return {
      isDevelopment: process.env.NODE_ENV === 'development',
    };
  },
  methods: {
    log() {
      if (this.isDevelopment) {
        console.log.apply(null, arguments);
      }
    },
    warn() {
      if (this.isDevelopment) {
        console.warn.apply(null, arguments);
      }
    },
    error() {
      if (this.isDevelopment) {
        console.error.apply(null, arguments);
      }
    },
  },
};
