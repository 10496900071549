import axios from 'axios';

function sortStations(stations) {
  return stations.sort((a, b) => {
    if (a.group < b.group) {
      return -1;
    }
    if (a.group > b.group) {
      return 1;
    }

    return a.name - b.name;
  });
}

function getGroupedStations(stations) {
  if (!stations || !stations.length) {
    return [];
  }

  var groups = [];

  stations.map(function (doc) {
    doc.group = parseInt(doc.group);

    if (groups.indexOf(doc.group) > -1) {
      return;
    }

    groups.push(doc.group);
  });
  groups.sort();

  let result = groups.map(function (group) {
    group = stations.filter(function (doc) {
      return doc.group == group;
    });

    return group;
  });

  return result;
}

function getCurrentStationIndex(stations, currentStationName = null) {
  let currentIndex = 0;

  if (!currentStationName) {
    return 0;
  }

  stations.find((station, index) => {
    if (station.name == currentStationName) {
      currentIndex = index;
      return true;
    }
  });

  return currentIndex;
}

export default {
  namespaced: true,
  state: {
    stationList: [],
    stationGroups: [],
    player: null,
    currentStation: null,
  },
  mutations: {
    updateStations(state, stations) {
      state.stationList = stations;
      state.stationGroups = getGroupedStations(stations);
    },
    updatePlayer(state, player) {
      state.player = player;
    },
    changeStation(state, stationName) {
      console.debug('changingStation::', stationName);

      if (!stationName) {
        return;
      }

      if (!state.stationList.length) {
        return;
      }

      state.currentStation = state.stationList
        .filter((station) => {
          return station.name === stationName;
        })
        .pop();

      if (!state.currentStation || !state.currentStation.src) {
        return;
      }
    },
  },
  actions: {
    loadStations({ commit }) {
      return axios
        .get('/stations.json')
        .then((resp) => {
          if (resp.status !== 200) {
            console.error(resp.statusText);
            return;
          }

          commit('updateStations', sortStations(resp.data));
        })
        .catch(console.error);
    },
    previousStation({ state, commit }) {
      if (!state.stationList || !state.stationList.length) {
        console.debug('No stations to play.');
        return;
      }

      if (!state.currentStation) {
        commit('changeStation', state.stationList[0].name);
        return;
      }

      let currentIndex = getCurrentStationIndex(state.stationList, state.currentStation.name);
      currentIndex--;

      if (currentIndex < 0) {
        currentIndex = state.stationList.length - 1;
      }

      commit('changeStation', state.stationList[currentIndex].name);
    },
    nextStation({ state, commit }) {
      if (!state.stationList || !state.stationList.length) {
        console.debug('No stations to play.');
        return;
      }

      if (!state.currentStation) {
        commit('changeStation', state.stationList[0].name);
        return;
      }

      let currentIndex = getCurrentStationIndex(state.stationList, state.currentStation.name);
      currentIndex++;

      if (currentIndex >= state.stationList.length) {
        currentIndex = 0;
      }

      commit('changeStation', state.stationList[currentIndex].name);
    },
  },
};
