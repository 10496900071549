import Vue from 'vue';
import VueRouter from 'vue-router';
//import { analytics } from '../assets/js/analytics';

Vue.use(VueRouter);
let getMetaData = (args) => {
  const title = 'Radio Pootjie - Vertaal vir jou alles';
  const description =
    'This page was created so I could listen to radio stations without all the ads and distractions. It is also extremely lightweight and mobile compatible.';

  let defaults = {
    title: title,
    metaTags: [
      {
        name: 'Description',
        content: description,
      },
      {
        name: 'Keywords',
        content:
          'Listen, Online, Radio, OFM, 5FM, Rosestad, Classics, Oldies, Pop, Afrikaans, Hits, Music, 96.2, 100.6, 98.0, DJ, Top 40, Entertainment',
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
      {
        property: 'og:image',
        content: 'https://radio.pootjie.co.za/images/facebook-banner.png',
      },
      {
        property: 'og:url',
        content: 'https://radio.pootjie.co.za/',
      },
      {
        property: 'og:site_name',
        content: 'Radio Pootjie',
      },
    ],
  };

  return Object.assign({}, defaults, args);
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomePage'),
    meta: getMetaData(),
    children: [
      {
        path: '/information',
        name: 'Information',
        component: () =>
          import(/* webpackChunkName: "information" */ '../views/Information'),
        meta: getMetaData({
          title: 'Information | Radio Pootjie',
        }),
      },
      {
        path: '/disclaimer',
        name: 'Disclaimer',
        component: () =>
          import(/* webpackChunkName: "disclaimer" */ '../views/Disclaimer'),
        meta: getMetaData({
          title: 'Disclaimer | Radio Pootjie',
        }),
      },
      {
        path: '/issues',
        name: 'Issues',
        component: () =>
          import(/* webpackChunkName: "issues" */ '../views/Issues'),
        meta: getMetaData({
          title: 'Issues | Radio Pootjie',
        }),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // @ts-ignore
  window.umami && window.umami.trackView(to.path);

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]'),
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    return next();
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
