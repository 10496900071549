/* eslint-disable no-undef */
import { mapState, mapActions } from 'vuex';
import console from './console';

export default {
  name: 'MediaSession',
  mixins: [console],
  mounted() {
    this.log('test');
    if (typeof navigator.mediaSession === 'undefined') {
      this.log('MediaSession is not supported.');
      return;
    }

    const actionHandlers = [
      {
        action: 'previoustrack',
        handler: this.previousStation,
      },
      {
        action: 'nexttrack',
        handler: this.nextStation,
      },
    ];

    actionHandlers.forEach((entry) => {
      try {
        navigator.mediaSession.setActionHandler(entry.action, entry.handler);
      } catch (error) {
        this.error(`The MediaSession action ${entry.action} is not supported yet.`);
      }
    });
  },
  computed: {
    ...mapState('RadioStations', ['currentStation']),
  },
  methods: {
    ...mapActions('RadioStations', ['previousStation', 'nextStation']),
  },
  watch: {
    currentStation(newValue) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: newValue.name,
        artwork: [
          {
            src: '/icons/android-chrome-16x16.png',
            sizes: '16x16',
            type: 'image/png',
          },
          {
            src: '/icons/android-chrome-32x32.png',
            sizes: '32x32',
            type: 'image/png',
          },
          {
            src: '/icons/android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: '/icons/android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      });
    },
  },
};
